<template>
	<div class="gonghai">
		<div class="tablebox">
			<div class="tabletop">
				<div class="inputbox">
					<input type="" placeholder="关键词" name="" id="" value="" />
					<div class="flex"><i class="el-icon-search" style="color: #AAAAAA;"></i></div>
				</div>
				<div class="selectbox">
					<span>用户状态</span>
					<el-select v-model="company" placeholder="请选择">
						<el-option
							v-for="item in companyList"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						></el-option>
					</el-select>
				</div>
				<div class="selectbox">
					<span>用户角色</span>
					<el-select v-model="company" placeholder="请选择">
						<el-option
							v-for="item in companyList"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						></el-option>
					</el-select>
				</div>
				<div class="selectbox">
					<span>创建日期</span>
					<el-date-picker v-model="value1" type="date" placeholder="选择日期"></el-date-picker>
				</div>
				<!-- <el-button class="chaxun flex">查询</el-button> -->
				<el-button class="chongzhi flex" style="margin-left: 2.5em;">查询</el-button>
				<el-button class="chongzhi flex">重置</el-button>
			</div>
			<div class="addbox" @click="showAdd" v-if="permissionList.includes('zhanghaoManage:add') || isAdmin">
				<i class="el-icon-plus" style="color: #ffffff;"></i>
				<span>新增</span>
			</div>

			<div>
				<el-table :data="tableData" border style="width: 100%">
					<el-table-column type="index" label="序号"></el-table-column>
					<el-table-column prop="loginName" label="账号"></el-table-column>
					<el-table-column prop="realName" label="姓名"></el-table-column>
					
					<el-table-column prop="mobile" label="手机号"></el-table-column>
					<el-table-column prop="roleName" label="所属角色"></el-table-column>
					<el-table-column prop="departmentName" label="所属部门"></el-table-column>
					<!-- <el-table-column prop="roleName" label="职务"></el-table-column> -->
					<el-table-column prop="createdTime" label="创建时间"></el-table-column>


					<el-table-column label="操作" width="200">
						<template slot-scope="scope">
							<div class="twobtn">
						

								<div class="kanbox gai" @click="showEdit(scope.row)" v-if="permissionList.includes('zhanghaoManage:edit') || isAdmin">
									<img src="@/assets/btnicon/image3.png" />
									改
								</div>

								<div class="kanbox shan" @click="showDelete(scope.row.userId)" v-if="isAdmin">
									<img src="@/assets/btnicon/image6.png" />
									删
								</div>
							</div>
						</template>
					</el-table-column>
				</el-table>

				<div class="pagebox">
					<el-pagination
						background
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page="searchInfo.page"
						:page-sizes="[5, 10, 15, 20]"
						:page-size="searchInfo.pageSize"
						layout="total, sizes, prev, pager, next, jumper"
						:total="totalCount"
					></el-pagination>
				</div>
			</div>
		</div>
		<delete-zhanghao @deleteInfo="_deleteStaff" message="确认要删除账号吗？" ref="deleteZhanghao"></delete-zhanghao>
		<add-zhanghao @updataInfo="_staffList" ref="addZhanghao"></add-zhanghao>
		<edit-zhanghao @updataInfo="_staffList" ref="editZhanghao"></edit-zhanghao>
	</div>
</template>

<script>
	import { staffList,deleteStaff } from "@/network/api.js"
import deleteZhanghao from "../../../components/common/deletePopup.vue"
import addZhanghao from "../popup/addZhanghao.vue"
import editZhanghao from "../popup/editZhanghao.vue"
export default {
	data() {
		return {
			value1: '',
			totalCount: 0,
			deleteId: '',
			companyList: [
				{
					value: '阿里',
					lable: '阿里'
				}
			],
			searchInfo: {
				disabled: '',
				endDate: '',
				isAsc: 'asc',
				orderByColumn: '',
				page: 1,
				pageSize: 10,
				roleId: '',
				searchBody: '',
				startDate: '',
			},
			company: '',
			tableData: []
		};
	},
	components: {
		addZhanghao,
		deleteZhanghao,
		editZhanghao
	},
	name: 'zhiwuManage',
	computed: {
		permissionList() {
			return this.$store.state.permissionList 
		},
		isAdmin() {
			return (this.$store.state.isadmin && localStorage.getItem('isStaff') == '0')
		},
	},
	created() {
		this._staffList()
	},
	methods: {
		// 删除账号
		async _deleteStaff() {
			console.log(111);
			const res = await deleteStaff({staffId: this.deleteId})
			this.$myMessage('删除成功','success')
			this.$refs.deleteZhanghao.dialogVisible = false
			this._staffList()
		},
		// 获取员工列表
		async _staffList() {
			const res = await staffList(this.searchInfo)
			console.log('员工列表',res);
			this.tableData = res.data.list
			this.totalCount = res.data.totalCount
		},
		// 点击出现修改弹框
		showEdit(info) {
			this.$refs.editZhanghao._staffDetail(info.userId)
			this.$refs.editZhanghao.drawer = true
			
		},
		// 点击出现删除弹框
		showDelete(id) {
			this.deleteId = id
			this.$refs.deleteZhanghao.dialogVisible = true
		},
		// 点击出现添加弹框
		showAdd() {
			this.$refs.addZhanghao.drawer = true
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			console.log(`当前页: ${val}`);
		}
	}
};
</script>

<style scoped lang="scss">

::v-deep {
	.pagebox {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		margin: 6.25em 0 0 1.25em;
		padding: 0 1.25em 0 0;
		.el-input__inner {
			color: #666666;
			width: 3em;
			height: 2.25em;
			line-height: 2.25em;
		}
		.el-select {
			.el-input__inner {
				color: #666666;
				width: 8em;
				height: 2.25em;
				line-height: 2.25em;
			}
		}

		.el-input__icon {
			line-height: 2.25em;
		}
	}
}
.kanbox:active {
	opacity: 0.6;
}
.kanbox {
	cursor: pointer;
	width: 3.625em;
	height: 1.625em;
	background: #0091ff;
	border-radius: 0.8125em;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 0.675em;
	font-size: 0.875em;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #ffffff;
	img {
		width: 0.9375em;
		height: 1.0625em;
	}
	&.shan {
		background-color: #b3bcc9;
		margin: 0 0 0 1.25em;
		img {
			width: 0.9375em;
			height: 1em;
		}
	}
	&.gen {
		background-color: #fa7e33;
		margin: 0 0 0 1.25em;
		img {
			width: 0.8125em;
			height: 1.0625em;
		}
	}
	&.gai {
		background-color: #3DC57C;
		margin: 0 0 0 1.25em;
		img {
			width: 0.875em;
			height: 0.875em;
		}
	}
	&.zeng {
		background-color: #9f7eff;
		margin: 0 0 0 1.25em;
		img {
			width: 0.875em;
			height: 0.8125em;
		}
	}
	img {
		width: 1em;
		height: 0.9375em;
	}
}
.twobtn {
	display: flex;
	align-items: center;
	justify-content: center;
}
::v-deep {
	.cell {
		text-align: center;
	}
	tr {
		font-size: 0.975em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 400;
		color: #868e99;
	}
	th {
		background-color: #f6f7fb !important;
		font-size: 0.975em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #5b6271;
	}
}
thead {
}
.addbox:active {
	opacity: 0.6;
}
.addbox {
	margin: 1.5625em 0 1.25em 0;
	cursor: pointer;
	width: 6.75em;
	height: 2.25em;
	background: linear-gradient(270deg, #2d74f4 0%, #3286ff 100%);
	border-radius: 0.25em;
	display: flex;
	align-items: center;
	justify-content: center;
	span {
		font-size: 0.875rem;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #ffffff;
		margin: 0 0 0 1.25em;
	}
}
::v-deep {
	.el-button {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
.chongzhi {
	width: 5.625em;
	height: 2.25em;
	font-size: 0.875em;
	margin: 0 0 0 1.25em;
}
.chaxun {
	margin: 0 1.25em 0 2.5em;
	width: 5.625em;
	height: 2.25em;
	background: rgba(50, 134, 255, 0.1);
	// border-radius: 0.25em;
	border: 1px solid #4d91ff;
	font-size: 0.875em;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #3286ff;
}
.tabletop {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}
::v-deep {
	.el-input__inner {
		color: #666666;
		width: 10.5em;
		height: 2.25em;
		line-height: 2.25em;
	}
	.el-input__icon {
		line-height: 2.25em;
	}
}
::v-deep {
	.selectbox {
		margin: 0 0 0 2.5em;
		display: flex;
		align-items: center;
		// overflow: hidden;
		span {
			font-size: 0.875em;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #333333;
			margin: 0 0.6875em 0 0;
		}
		.el-input__inner {
			color: #666666;
			width: 9.75em;
			height: 2.25em;
			line-height: 2.25em;
		}
	}
}

.tablebox {
	background-color: #ffffff;
	padding: 1rem 1.25em;
}
.inputbox {
	display: flex;
	align-items: center;
	background: #ffffff;
	border-radius: 0.25em;
	border: 1px solid #e4e4e4;
	height: 2.25em;
	width: 12.5em;
	justify-content: space-between;
	input {
		outline: none;
		border: 0;
		width: 10.25em;
		font-size: 0.875em;
		padding: 0 0.375em 0 0.875em;
	}
	div {
		width: 2.25em;
		height: 2.25em;
		background: #faf9fb;
		border-radius: 0px 0.25em 0.25em 0px;
		border: 1px solid #e4e4e4;
	}
}

.gonghai {
	padding: 0.9375em 1.25em;
}
@media screen and (max-width: 1920px) {
	.gonghai {
		font-size: 16px;
	}
}
@media screen and (max-width: 1720px) {
	.gonghai {
		font-size: 15px;
	}
}
@media screen and (max-width: 1520px) {
	.gonghai {
		font-size: 14px;
	}
}
@media screen and (max-width: 1320px) {
	.gonghai {
		font-size: 13px;
	}
}
@media screen and (max-width: 1120px) {
	.gonghai {
		font-size: 12px;
	}
}
</style>
